import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { ModalModule } from 'ngx-bootstrap/modal';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { ProgressSpinnerModule } from 'primeng/progressspinner';

import { InputMaskModule } from 'primeng/inputmask';
import { LoaderComponent } from '../../layout/loader/loader.component';
import { LoaderBlackComponent } from '../../layout/loader/loader-black.component';
import { LoaderWhiteComponent } from '../../layout/loader/loader-white.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxPasswordToggleModule } from 'ngx-password-toggle';
import { ConfirmationDialogComponent } from './confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogService } from './confirmation-dialog/confirmation-dialog.service';
import { TruncateWord, UtcDateTimeToLocalPipe, UtctolocalPipe } from '../../pipes/truncate.pipe';
import { BsModalService } from 'ngx-bootstrap/modal';
import { CreditCardDirectivesModule } from 'angular-cc-library';
import { SafeHtmlPipe } from '../../pipes/pipe.safeHtml';

@NgModule({
  declarations: [
 
    LoaderComponent,
    LoaderBlackComponent,
    LoaderWhiteComponent,
    ConfirmationDialogComponent,
    UtcDateTimeToLocalPipe,
    UtctolocalPipe,
    SafeHtmlPipe
    ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule, ProgressSpinnerModule,
   NgSelectModule,
    InputMaskModule,
    ModalModule.forRoot(),
    CreditCardDirectivesModule
   
  ], providers: [
    DatePipe,
    ConfirmationDialogService,
  ],
  exports: [
    ReactiveFormsModule,
    FormsModule,
    NgSelectModule,
    InputMaskModule,
    NgxPasswordToggleModule,
     LoaderComponent,
    LoaderBlackComponent,
    LoaderWhiteComponent, ProgressSpinnerModule,
    UtcDateTimeToLocalPipe,
    UtctolocalPipe,
    CreditCardDirectivesModule,
    SafeHtmlPipe
  ],
  entryComponents: [
    ConfirmationDialogComponent
  ]
})
export class SharedModule { }
