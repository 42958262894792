import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { ConfirmationDialogService } from './confirmation-dialog/confirmation-dialog.service';
import * as signalR from '@aspnet/signalr';

import { Observable, BehaviorSubject } from 'rxjs';
@Injectable({
  providedIn: 'root'
})
export class CommonService {

  public baseUri = `${environment.WebApiBaseUrl}`;
  public SignalbaseUri = 'https://admin.eztruckspermit.com/';
  public getLoggedInName = new BehaviorSubject<UserDetails>(new UserDetails());
  hubConnection: signalR.HubConnection;
  LoginUser: UserDetails = new UserDetails();
  ConnectionID: string = null;
    isUploadImageValid: boolean;
    isFileValid: boolean;
  constructor(private http: HttpClient, private toaster: ToastrService, private routerService: Router,
    private dialogService: ConfirmationDialogService) {
    this.hubConnection = new signalR.HubConnectionBuilder().withUrl("/userhub").withAutomaticReconnect().configureLogging(signalR.LogLevel.Information).build();
    
  }

  getStateList() {
    return this.http.get(this.baseUri + `Customer/GetStateList`);
  }

  getCountries() {
    return this.http.get(this.baseUri + `Customer/GetCountriesList`);
  }
  getConnectionID(cartId) {
    if (cartId == null)
      cartId ="00Tets"
    return this.http.get(this.baseUri + `news/GetConnectionID/${cartId}`);
  }
  validateAllFormFields(formGroup: FormGroup, iscroll = true) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
        return;
      }
      else if (control instanceof FormArray) {
        control.controls.forEach(arrControl => {
          if (arrControl instanceof FormGroup) {
            this.validateAllFormFields(arrControl);
            return;
          }
        })
        if (iscroll) {
          this.scroll(formGroup);
        }
      }

    });

  }

  scroll(form: FormGroup) {
    this.scrollIfFormHasErrors(form).then(() => {
    });
  }

  async scrollIfFormHasErrors(form: FormGroup): Promise<any> {
    await form.invalid;
    this.scrollToError();
  }

  scrollToError(): void {
    const firstElementWithError = document.querySelector('.ng-invalid');
    this.scrollTo(firstElementWithError);
  }

  scrollTo(el: Element) {
    if (el) {
      el.scrollIntoView({ behavior: "smooth" });
    }
  }

  uploadDocumentSize(type: string, filesize: number, filelength: string) {

    if (filelength == "30MB") {

      if (type.toLowerCase() !== '') {
        if (filesize >= 31457280) {    // 30MB Size in Bytes.
          this.toaster.error("Please upload a file of max. size 30MB.");
          this.isFileValid = false;
        }
        else {
          this.isFileValid = true;
        }
      }

    }
    else if (filelength == "10MB") {

      if (type.toLowerCase() !== '') {
        if (filesize >= 10485760) { //10MB Size in Bytes.
          this.toaster.error("Please upload a file of max. size 10MB.");
          this.isFileValid = false;
        }
        else {
          this.isFileValid = true;
        }
      }
    }
    else {
      if (type.toLowerCase() !== '') {
        if (filesize >= 5242880) {//5MB Size in Bytes.
          this.toaster.error("Please upload a file of max. size 5MB.");
          this.isFileValid = false;
        }
        else {
          this.isFileValid = true;
        }
      }
    }
  }
  uploadImageFileValidator($file) {
    const fileName = $file.target.files[0].name;
    const extensions = [".png", ".jpeg", ".jpg", ".gif"];
    let blnValid = false; this.isUploadImageValid = false
    for (var j = 0; j < extensions.length; j++) {
      var sCurExtension = extensions[j];
      if (fileName.substr(fileName.length - sCurExtension.length, sCurExtension.length).toLowerCase() == sCurExtension.toLowerCase()) { blnValid = true; break; }
    }
    if (!blnValid) {
      $file.target.value = ""; this.toaster.error(`Valid file format is ${extensions.join(", ")}`);
    }
    else {
      this.isUploadImageValid = true;
    }
  }
  logout() {
    const message = `Are you sure you want to Logout ?`;
    this.dialogService.confirm('Logout', message).subscribe(confirmed => {
      if (confirmed) {
        localStorage.removeItem("Customer_access_token");
        localStorage.removeItem("CustomerLoginUserId");
        localStorage.removeItem("CurrentCartId");
        localStorage.removeItem("CustomerName");
        localStorage.removeItem("CustInfoShippingInformation");
        //ShippingInformation
        localStorage.removeItem('custuserinfo');
        localStorage.removeItem("ShippingInformation");
        localStorage.removeItem("CartCount");
        localStorage.removeItem("LocalTime");
        localStorage.removeItem("currentOrderId");
        localStorage.removeItem('custTimeZone');
        this.routerService.navigate(['/customeraccount'])
          .then(() => {
            window.location.reload();
          });
        this.toaster.success(`You are logout out successfully`);
      }
      else {
      }
    });


  }

  getCurrentUserDetail(userId, actionType = '') {
    if (actionType == '' || actionType == null || typeof actionType == undefined) {
      actionType = '00Test';
    }
    if (localStorage.getItem("actionType") == 'AsAObserver') {
      actionType = localStorage.getItem("actionType");
    }
    let optionaParamersType = "00Test";
    return this.http.get(this.baseUri + `UserProfile/GetUserInfo/${userId}/${actionType}/${optionaParamersType}`).subscribe((response: any) => {
      if (response != null) {
        this.LoginUser = response;

        localStorage.removeItem('custuserinfo');

        localStorage.setItem('custuserinfo', JSON.stringify(response));
        localStorage.setItem("custUserType", response.userType);
        localStorage.removeItem('custTimeZone');
        var TimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone; //response.timeZone;
        localStorage.setItem("custTimeZone", TimeZone);


        this.getLoggedInName.next(this.LoginUser);
      }
      else {

       
        localStorage.removeItem("custuserinfo");
        this.LoginUser = new UserDetails();
        
      }

    },
      (error: any) => {
        
        localStorage.removeItem("custuserinfo");
        this.LoginUser = new UserDetails();
        
      })

  }

}
export class UserDetails {
  id: string;
  email: string;
  firstName: string = '';
  lastName: string = '';
  userType: string;
  fullName = this.firstName + " " + this.lastName;
}
