import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { Injectable } from '@angular/core';
const routes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: './views/home/home.module#HomeModule',
  },
  {
    path: 'FAQ',
    loadChildren: './views/dynamicpagesfront/dynamicpages.module#DynamicpagesModule'
  },
  {
    path: 'About',
    loadChildren: './views/dynamicpagesfront/dynamicpages.module#DynamicpagesModule'
  },
  {
    path: 'PrivacyPolicy',
    loadChildren: './views/dynamicpagesfront/dynamicpages.module#DynamicpagesModule'
  },
  {
    path: 'AllTruckPermits',
    loadChildren: './views/dynamicpagesfront/dynamicpages.module#DynamicpagesModule'
  },
  {
    path: 'IRPLicensePlates',
    loadChildren: './views/dynamicpagesfront/dynamicpages.module#DynamicpagesModule'
  },
  {
    path: 'BOC3Filing',
    loadChildren: './views/dynamicpagesfront/dynamicpages.module#DynamicpagesModule'
  },
  {
    path: 'DrugAlcohalConsortium',
    loadChildren: './views/dynamicpagesfront/dynamicpages.module#DynamicpagesModule'
  },


  {
    path: 'dotinformation',
    loadChildren: './views/dynamicpagesfront/dynamicpages.module#DynamicpagesModule'
  },
  {
    path: 'dqfiles',
    loadChildren: './views/dynamicpagesfront/dynamicpages.module#DynamicpagesModule'
  },
  {
    path: 'vehiclereq',
    loadChildren: './views/dynamicpagesfront/dynamicpages.module#DynamicpagesModule'
  },



  {
    path: 'SupervisorTraining',
    loadChildren: './views/dynamicpagesfront/dynamicpages.module#DynamicpagesModule'
  },
  
  {
    path: 'UCRUnifiedCarrierRegistration',
    loadChildren: './views/dynamicpagesfront/dynamicpages.module#DynamicpagesModule'
  },
  {
    path: 'DOTRegistration',
    loadChildren: './views/dynamicpagesfront/dynamicpages.module#DynamicpagesModule'
  },
  {
    path: 'VINVerification',
    loadChildren: './views/dynamicpagesfront/dynamicpages.module#DynamicpagesModule'
  },
  {
    path: 'contactus',
    loadChildren: './views/ContactUsFrontEnd/contactus.module#ContactusModule'
  },
  {
    path: 'TermConditions',
    loadChildren: './views/dynamicpagesfront/dynamicpages.module#DynamicpagesModule'
  },
{
    path: 'DruckAllocholTesting',
    loadChildren: './views/dynamicpagesfront/dynamicpages.module#DynamicpagesModule'
  },
  {
    path: 'ComplianceServices',
    loadChildren: './views/dynamicpagesfront/dynamicpages.module#DynamicpagesModule'
  },
  {
    path: 'OpeningNewCompany',
    loadChildren: './views/dynamicpagesfront/dynamicpages.module#DynamicpagesModule'
  },
  {
    path: 'AboutEZTrucksPermit',
    loadChildren: './views/dynamicpagesfront/dynamicpages.module#DynamicpagesModule'
  },
  {
    path: 'WhyChooseUs',
    loadChildren: './views/dynamicpagesfront/dynamicpages.module#DynamicpagesModule'
  },
  {
    path: 'dashboard',
    loadChildren: './views/dashboard/customerdashboard.module#CustomerdashboardModule'
  },
  {
    path: 'customer',
    loadChildren: './views/customer/customer.module#CustomerModule'
  },
  {
    path: 'AccountDetails',
    loadChildren: './views/UpdateAccountDetails/UpdateAccountDetail.module#UpdateAccountDetailModule'
  },
  {
    path: 'customeraccount',
    loadChildren: './views/account/account.module#AccountModule',
  },
  {
    path: 'selfregistration',
    loadChildren: './views/employeeregister/employeeregister.module#employeeregisterModule'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})

export class AppRoutingModule { }
