import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { environment } from '../environments/environment';
import { CommonService } from './views/shared/common.service';
import * as signalR from '@aspnet/signalr';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent implements OnInit {
  title = 'EZ Truck Permits';
  showHeader = false;
  showSidebar = false;
  islogin = false;
  showFooter = false;
  public baseUri = `${environment.WebApiBaseUrl}`;

  constructor(private router: Router, private activatedRoute: ActivatedRoute, private commonService: CommonService,) {
    let currentContext = this;
    
  }

  ngOnInit() {
    
    this.router.events.subscribe(event => {
 
      if (event instanceof NavigationEnd) {
        this.showHeader = this.activatedRoute.firstChild.snapshot.data.showHeader !== false;
        this.showSidebar = this.activatedRoute.firstChild.snapshot.data.showSidebar !== false;
        this.showFooter = this.activatedRoute.firstChild.snapshot.data.showFooter !== false;
      }
    });
    
  }
}
