import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from '../../views/shared/common.service';
import * as signalR from '@aspnet/signalr';
import { CustomerService } from '../../views/shared/customer.service';
import { environment } from '../../../environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {
  @Input() LoggedIn 
  isLoggedIn = false;
  hubConnection: signalR.HubConnection;
  count: number = 0;
  public baseUri = `${environment.PublicApiBaseUrl}`;
  constructor(private commonService: CommonService, 
    private customerService: CustomerService) { }

  ngOnInit() {
    if (localStorage.getItem('CustomerLoginUserId') != null && localStorage.getItem('CustomerLoginUserId') != '') {
      this.isLoggedIn = true;
    }
    else {
      this.isLoggedIn = false;
    }
    this.prepareSignalR();

    let baseUri = 'https://admin.eztruckspermit.com/';
    const connection = new signalR.HubConnectionBuilder()
      .configureLogging(signalR.LogLevel.Information)
      .withUrl(baseUri)
      .build();

    connection.start().then(function () {
      console.log('SignalR Connected!');
    }).catch(function (err) {
      return console.error(err.toString());
    });

    connection.on("BroadcastMessage", () => {
      this.getHeaderCartNotification();
    });
  }

  logout() {
    this.commonService.logout();
  }


  prepareSignalR() {
    this.commonService.hubConnection.on("sendNotificationToClient", (message: string, isShowToaster: boolean) => {
      
      this.getHeaderCartNotification(isShowToaster, message);

    });
  }

  getHeaderCartNotification(isNew = false, message = null) {
    this.customerService.GetCartItemCount(localStorage.getItem('CurrentCartId'), localStorage.getItem('CustomerLoginUserId')).subscribe((data: any) => {
     
      this.count = data;
    })
  }
  
}
