import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-loader-white',
  templateUrl: './loader-white.component.html',
})
export class LoaderWhiteComponent implements OnInit {

  constructor() { }
  @Input() size: number;
  ngOnInit() {
    //alert(1);
  }

}
