import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from '../../views/shared/common.service';
import { LayoutService, tbemailNgModel } from '../layout.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html'
})
export class FooterComponent implements OnInit {
  emailsubscriptionForm: FormGroup;
  emailmodel: tbemailNgModel = new tbemailNgModel();
  loading: boolean;
  response: any;
  messageText: any;
  emailTo: any;
  emailSubject: string;
  loadSave: boolean;
  sendEmail: any;
  testEmailForm: any;
  toaster: any;
  topFiveCategory: any;
  constructor(private fb: FormBuilder,
    private layoutService: LayoutService,
    private commonService: CommonService,
    private route: ActivatedRoute,
    private routers: Router) { }

  ngOnInit() {
    //this.gettopCategories();
    this. initForm();
  }
  public initForm() {
    this.emailsubscriptionForm = this.fb.group({

      subscriberEmail: ['', [Validators.required, Validators.email, Validators.pattern('^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$')]],

    });
  };
  get subscriberEmail() { return this.emailsubscriptionForm.get("subscriberEmail"); }
  submit() {
    this.loading = true;
    this.emailmodel.subscriberEmail = this.emailsubscriptionForm.value.subscriberEmail;
    this.layoutService.subscribeemail(this.emailmodel).subscribe((result: any) => {
      if (result.statusCode == 200) {
        debugger;
        this.response = result.responseMessage;
        this.emailsubscriptionForm.reset();
      }
      else {
        this.loading = false;
      }
    }, error => {
      this.loading = false;
    });
  }
  gettopCategories() {
    this.layoutService.getTopCategory().subscribe((Response: any) => {
      this.topFiveCategory = Response;
     console.log("Cat", this.topFiveCategory);
    })
  }

}
