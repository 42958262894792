import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { LayoutModule } from './layout/layout.module';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgSelectModule } from '@ng-select/ng-select';
import { InputMaskModule } from 'primeng/inputmask';
import { CommonService } from './views/shared/common.service';
import { SharedModule } from './views/shared/shared.module';



@NgModule({
  declarations: [
    AppComponent
    

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    RouterModule,
    ReactiveFormsModule, NgxPaginationModule,
    NgSelectModule,
    LayoutModule,
    CarouselModule,
    BrowserAnimationsModule,
    SharedModule,
    
    ToastrModule.forRoot()
    ],
  providers: [CommonService],
  bootstrap: [AppComponent]
})
export class AppModule { }
