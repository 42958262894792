import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class LayoutService {
    public baseUri = `${environment.WebApiBaseUrl}`;
    constructor(private http: HttpClient) { }

  getTopCategory() {
    return this.http.get(this.baseUri + 'HomeFront/GetTopFiveCategory');
  }
  subscribeemail(Model: tbemailNgModel) {
    return this.http.post(this.baseUri + `HomeFront/Subscribeemail`, Model)
  }
}
export class tbemailNgModel {
  subscriberId: string;
  subscriberEmail: string;
  constructor() {
    this.subscriberId = null;
    this.subscriberEmail = "";

  }
}
