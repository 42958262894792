import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  public baseUri = `${environment.WebApiBaseUrl}`;
  constructor(private http: HttpClient) { }

  Customerlogin(CustomerLoginModel: CustomerLogin) {
    return this.http.post(this.baseUri + 'CustomerLogin', CustomerLoginModel);
  }

  ForgotPsw(forgotPswModel: ForgotPassword) {
    return this.http.post(this.baseUri + `CustomerLogin/ForgotPassword`, forgotPswModel);
  }

  resetPassword(resetPassword: ResetPassword) {
    return this.http.post(this.baseUri + `CustomerLogin/ResetPassword`, resetPassword);
  }

  changePassword(changePassword: ChangePassword) {
    return this.http.post(this.baseUri + `CustomerLogin/ChangePassword`, changePassword);
  }
  updateCartItemActualUserId(currentCartId, customeruserId) {
    return this.http.get(this.baseUri +`news/UpdateCartIteamActualUserId/${currentCartId}/${customeruserId}`)
  }

  GetCartItemCount(cardId, userId): Observable<number> {
    if (cardId == null)
      cardId = "00Tets"
    if (userId == null)
      userId ="00Tets"
    return this.http.get<number>(this.baseUri + `news/GetCartIteamCount/${cardId}/${userId}`)
  }


}


export class CustomerLogin {
  userName: string;
  password: string;
  rememberMe: boolean;
  browser: string;
  os: string;
  iPAddress: string
  constructor() {
    this.userName = '';
    this.password = '';
    this.rememberMe = false;
    this.browser = '';
    this.os = '';
    this.iPAddress = '';
  }
}

export class ChangePassword {
  currentPassword: string;
  password: string;
  confirmPassword: string;
  userId: any;
  constructor() {
    this.currentPassword = "";
    this.password = "";
    this.confirmPassword = "";
    this.userId = null;
  }
}

export class ResetPassword {
  password: string;
  confirmPassword: string;
  userId: any;
  resetToken: any;
  constructor() {
    this.password = "";
    this.confirmPassword = "";
    this.userId = null;
  }
}

export class ForgotPassword {
  username: string;

  constructor() {
    this.username = '';
  }
}
